<template>
  <main class="kb-main" id="kb-edutrain">
    <lxp-main-header :show-title="true" :show-breadcrumb="true" :show-excel-download="true">
      <template v-slot:action>
        <HistoryYearSelector v-model="yearStr" :show-select-all="true" :yearCnt="6" />
      </template>
    </lxp-main-header>
    <!-- main-content -->
    <div class="main-content min-component">
      <!-- content-section -->
      <section class="content-section section-status">
        <div class="roundedbox-wrap roundedbox-low">
          <!-- roundedbox -->
          <div class="roundedbox">
            <div class="roundedbox-top">
              <div class="top-row">
                <h5 class="roundedbox-title">누적 연수 시간</h5>
              </div>
            </div>
            <div class="roundedbox-bottom">
              <span class="roundedbox-text">19일 47시간</span>
            </div>
          </div>
          <!-- roundedbox -->
          <div class="roundedbox">
            <div class="roundedbox-top">
              <div class="top-row">
                <h5 class="roundedbox-title">누적 완료 연수</h5>
              </div>
            </div>
            <div class="roundedbox-bottom">
              <span class="roundedbox-text">28개</span>
            </div>
          </div>
          <!-- roundedbox -->
          <div class="roundedbox">
            <div class="roundedbox-top">
              <div class="top-row">
                <h5 class="roundedbox-title">수료결과율</h5>
              </div>
            </div>
            <div class="roundedbox-bottom">
              <span class="roundedbox-text">89%</span>
            </div>
          </div>
          <!-- roundedbox -->
          <div class="roundedbox">
            <div class="roundedbox-top">
              <div class="top-row">
                <h5 class="roundedbox-title">평균 최종점수</h5>
              </div>
            </div>
            <div class="roundedbox-bottom">
              <span class="roundedbox-text">92점</span>
            </div>
          </div>
        </div>
      </section>
      <!-- content-section:완료 연수 분류 -->
      <section class="content-section section-chart section-divider">
        <header class="section-header">
          <h4 class="title">완료 연수 분류</h4>
        </header>
        <!-- chart-wrap -->
        <div class="chart-wrap">
          <!-- chart -->
          <ToastPieChart />
<!--          <div class="chart chart-pie">-->
<!--            <img src="../../../assets/lxp/images/@tmp/@tmp_charB_01.png" alt="임시이미지" width="284" />-->
<!--          </div>-->
          <!-- chart-details -->
          <div class="chart-details">
            <div class="chart-rank">
              <div class="kb-table kb-table-striped">
                <table>
                  <colgroup>
                    <col style="width:45px;" />
                    <col style="width:auto;" />
                    <col style="width:auto;" />
                  </colgroup>
                  <tbody>
                  <tr>
                    <th><strong class="th-title text-primary rank">1</strong></th>
                    <td><strong class="td-text">직무과정</strong></td>
                    <td class="text-end"><strong class="td-text text-muted">49%</strong></td>
                  </tr>
                  <tr>
                    <th><strong class="th-title rank">2</strong></th>
                    <td><strong class="td-text">기본과정</strong></td>
                    <td class="text-end"><strong class="td-text text-muted">27%</strong></td>
                  </tr>
                  <tr>
                    <th><strong class="th-title rank">3</strong></th>
                    <td><strong class="td-text">학습리더</strong></td>
                    <td class="text-end"><strong class="td-text text-muted">22%</strong></td>
                  </tr>
                  <tr>
                    <th><strong class="th-title rank">4</strong></th>
                    <td><strong class="td-text">자기주도학습</strong></td>
                    <td class="text-end"><strong class="td-text text-muted">15%</strong></td>
                  </tr>
                  </tbody>
                </table>
              </div>
            </div>
          </div>
        </div>
        <!-- //chart-wrap -->
        <!-- learning-status-wrap -->
        <div class="learning-status-wrap">
          <div class="list-top">
            <div class="top-column align-self-end">
              <p class="title text-muted">총 {{ paging.totalCount }}건</p>
            </div>
            <div class="top-column">
              <div class="kb-form-search">
                <div class="kb-form-search-field">
                  <input type="text" class="kb-form-search-input" placeholder="검색"
                         @keyup.enter="clickSearch"
                         v-model="params.crseNm"
                  />
                  <button class="kb-form-search-btn" @click="clickSearch"><i class="icon-search" >검색</i></button>
                </div>
              </div>
            </div>
          </div>

          <div class="kb-table kb-table-bordered learning-tablelist">
            <table>
              <colgroup>
                <col style="width:auto" />
                <col style="width:213px" />
                <col style="width:120px" />
                <col style="width:120px" />
                <col style="width:162px" />
              </colgroup>
              <tbody>
              <tr v-for="(item, idx) in regularList" :key="idx">
                <td>
                  <div class="item-content">
                    <div class="content-symbol"><i :class="`symbol-type-${item.crseToIdStr}`"></i></div>
                    <div class="content-details">
                      <h5 class="content-title"><a href="javascript:" class="title-link">{{ item.crseNm }}</a></h5>
                      <p class="content-text"><span class="text">{{ item.crseToNm }}</span><span class="text">{{ item.hostNm }}</span></p>
                    </div>
                  </div>
                </td>
                <td><div class="item-date"><span class="text">{{ ymdStrToFormat(item.startDt, '.') }}-{{ ymdStrToFormat(item.endDt, '.') }}</span></div></td>
                <td><div class="item-evaluation"><span class="text">{{ item.totalPoint > 0 ? item.totalPoint : '-' }}</span></div></td>
                <td><div class="item-status"><strong class="text">{{ item.crseStatus }}</strong></div></td>
                <td><div class="item-actions">
                  <a href="javascript:" v-if="item.reviewYn == 'y'" class="kb-btn kb-btn-primary"><span class="text">복습하기</span></a>
                  <span v-else class="text">-</span>
                </div>
                </td>
              </tr>
              </tbody>
            </table>
          </div>
          <CommonPaginationFront :paging="paging" :page-func="pagingFunc" />
        </div>
        <!-- //learning-status-wrap -->
      </section>
    </div>
    <!-- //main-content -->
  </main>
  <!-- end::kb-main -->
</template>
<script>
import LxpMainHeader from '@/components/common/LxpMainHeader';
import {onBeforeMount, onMounted, reactive, ref, watch} from 'vue';
import {getCheckItems, getPaging, initPaging, setParamsByQueryString, ymdStrToFormat} from '@/assets/js/util';
import {ACT_GET_TRAIN_REGULAR_LIST} from '@/store/modules/train/train';
import {useStore} from 'vuex';
import HistoryYearSelector from '@/components/history/HistoryYearSelector';
import {useRoute, useRouter} from 'vue-router';
import CommonPaginationFront from '@/components/CommonPaginationFront';
import ToastPieChart from '@/components/chart/ToastPieChart';

export default {
  name: 'HistoryRegular',
  components: {ToastPieChart, HistoryYearSelector, CommonPaginationFront, LxpMainHeader},
  setup() {
    const route = useRoute();
    const router = useRouter();
    const store = useStore();

    const paging = ref(initPaging(route));

    const yearStr = ref(new Date().getFullYear());

    const params = reactive({
      crseNm: '',
    });

    const regularList = ref([]);

    const getRegularList = async () => {
      await store.dispatch(`train/${ACT_GET_TRAIN_REGULAR_LIST}`, {
        ...params,
        yearStr: yearStr.value,
        ...paging.value
      }).then(res => {
        regularList.value = getCheckItems(res);
        paging.value = getPaging(res);
      }).catch(() => {});
    };

    const pagingFunc = (pageNo, pageSize) => {
      const query = {pageNo: pageNo, year: yearStr.value};
      if (pageSize !== paging.value.pageSize) {
        query.pageSize = pageSize;
      }
      if (params.crseNm) query.crseNm = params.crseNm;
      if (yearStr.value) query.year = yearStr.value;
      router.push({query: query});
    };

    const clickSearch = () => {
      pagingFunc(1, 10);
    };

    watch(() => yearStr.value, () => {
      getRegularList();
    });

    watch(() => route.query, () => {
      setParamsByQueryString(route, 'HistoryRegular', paging, params, true);
      getRegularList();
    });

    onBeforeMount(() => {
      setParamsByQueryString(route, 'HistoryRegular', paging, params, true);
    });

    onMounted(() => {
      getRegularList();
    });

    return {
      params,
      yearStr,
      regularList,
      ymdStrToFormat,
      paging,
      pagingFunc,
      clickSearch
    }
  }
};
</script>